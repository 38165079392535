import activity from './m-flyers.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Flyers',
  icon: 'mdi-file-document-outline',
  description: 'Have interns submit promotional flyers',
  status: true,
  setupRequired: true,
  styling: {
    borderColor: '#6EBA7F'
  },
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-flyers',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        fileType: ['.png', '.jpeg'],
        uploadLimit: {
          min: 2,
          max: 3
        }
      },
      value: {
        fileUpload: [
          {
            timestamp: '',
            fileName: '',
            file: null,
            description: ''
          }
        ]
      }
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
